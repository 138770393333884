import React from "react";
import Layout from "../../Components/layout";
import GetItems from "../../Components/getItems";
import { Seo } from "../../Components/seo";


export default function Shoes() {
  return (
    <Layout>
      <GetItems search="Men’s Shoes" title="Men's Shoes" shoes={true}/>
    </Layout>
  )
}
export const Head = () => (<Seo pathname={'./mens/shoes'} title="STM Quality Men's Shoes: Positivity and Awareness" description={"Shopping for men's shoes to spread positivity and awareness and promote Stronger Than Medicine (STM)."} />)